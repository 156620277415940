import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import { Guid } from 'guid-typescript';

import store from '@/infrastructure/store';
import OrderService from '@/services/OrderService';
import IOrderService, 
    { TipologiaFornituraModel, TipologiaFornituraCommand, EntityOrderStatus, EditSupplyTipologyCommand, EditSupplyTipologyResponse } from '@/services/iOrderService';
import pgaDiContainer from '@/App.container';

import { SharedModule } from '@/feature/Shared/store';
import { ApproveDeletionSupplyTipologyCommand, ApproveDeletionBusinessLineCommand, TipologiaFornituraResponse } from '../../../services/iOrderService';
import TipologiaFornituraDetails from './Components/PgaTipologiaFornituraDetails.vue';

export interface CreateTipologiaFornituraState {
    TipologiaFornituraCreate: TipologiaFornituraCommand;
    TipologiaFornitura: Array<TipologiaFornituraModel>;
    TipologiaFornituraDetails: TipologiaFornituraModel;
    SupplyTipologyEdit: EditSupplyTipologyModel;
}

export interface EditSupplyTipologyModel
{
    View: TipologiaFornituraModel | null;
    Command: EditSupplyTipologyCommand | null;
    Response: EditSupplyTipologyResponse | null;
    Errors: Map<string, Array<string>>;
}

@Module({ namespaced: true, dynamic: true, store, name: 'CreateTipologiaFornituraModule', })
class CreateTipologiaFornituraStore extends VuexModule implements CreateTipologiaFornituraState {

    private _orderService: IOrderService = pgaDiContainer.get<IOrderService>(OrderService);

    public TipologiaFornituraDetails: TipologiaFornituraModel = {
        Id: '',
        Code: '',
        Name: '',
        Status: EntityOrderStatus.Created,
        Users: [{ Id: '' }],
        TenantId: '',
    };

    public TipologiaFornituraCreate: TipologiaFornituraCommand = {
        Code: '',
        Name: '',
        Users: [{ Id: 'd2059981-0c8e-47fc-a547-84d88cd5a966' }],
        Errors: new Map<string, Array<string>>()
    };

    public SupplyTipologyEdit: EditSupplyTipologyModel = 
    {
        View: null,
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    }

    public TipologiaFornitura: Array<TipologiaFornituraModel> = [];

    @Action({ rawError: true })
    public async getAllTipologiaFornitura(): Promise<Array<TipologiaFornituraModel>> {
        const { TipologiaFornitura } = await this._orderService.GetAllTipologiaFornitura();
        this.setTipologiaFornitura(TipologiaFornitura);
        return TipologiaFornitura;
    }
    @Mutation
    public setTipologiaFornitura(tipologiaFornitura: Array<TipologiaFornituraModel>) {
        this.TipologiaFornitura = tipologiaFornitura
    }
    @Action({ rawError: true })
    public async createTipologiaFornitura(): Promise<TipologiaFornituraResponse> {

        try {
            SharedModule.SET_ISLOADING(true);
            const response = await this._orderService.CreateTipologiaFornitura(this.TipologiaFornituraCreate);
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_RESPONSE_CREATE(errs);

            throw error
        }
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURA_CODE_CREATE(Code: string) {
        this.TipologiaFornituraCreate.Code = Code;
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURA_NAME_CREATE(Name: string) {
        this.TipologiaFornituraCreate.Name = Name;
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURA_USER_CREATE(user: Array<any>) {
        this.TipologiaFornituraCreate.Users = user;
    }
    @Mutation
    public SET_RESPONSE_CREATE(errors: Map<string, Array<string>>) {
        this.TipologiaFornituraCreate.Errors = errors;
    }
    @Action({ rawError: true })
    public async getTipologiaFornituraById(Id: string): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        const TipologiaFornitura = await this._orderService.GetTipologiaFornituraById(Id);

        this.SET_TIPOLOGIAFORNITURA_ID(TipologiaFornitura.Id);
        this.SET_TIPOLOGIAFORNITURA_CODE(TipologiaFornitura.Code);
        this.SET_TIPOLOGIAFORNITURA_NAME(TipologiaFornitura.Name);
        this.SET_TIPOLOGIAFORNITURA_USER(TipologiaFornitura.Users);
        this.SET_TIPOLOGIAFORNITURA_TENANT(TipologiaFornitura.TenantId);
        this.SET_TIPOLOGIAFORNITURA_STATUS(TipologiaFornitura.Status);
        SharedModule.SET_ISLOADING(false);
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURA_ID(Id: string) {
        this.TipologiaFornituraDetails.Id = Id;
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURA_CODE(Code: string) {
        this.TipologiaFornituraDetails.Code = Code;
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURA_NAME(Name: string) {
        this.TipologiaFornituraDetails.Name = Name;
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURA_USER(user: Array<any>) {
        this.TipologiaFornituraDetails.Users = user;
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURA_TENANT(TenantId: string) {
        this.TipologiaFornituraDetails.TenantId = TenantId;
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURA_STATUS(Status: EntityOrderStatus) {
        this.TipologiaFornituraDetails.Status = Status;
    }
    @Action({ rawError: true })
    public async DeleteTipologiaFornituraAPI(Id: string): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        await this._orderService.DeleteTipologiaFornitura(Id);
        this.DROP_TIPOLOGIAFORNITURA(Id);
        SharedModule.SET_ISLOADING(false);
    }
    @Mutation
    public DROP_TIPOLOGIAFORNITURA(Id: string) {
        this.TipologiaFornitura = this.TipologiaFornitura.filter(item => item.Id !== Id);
    }

    @Action({ rawError: true })
    public RESET_STATE_CREATE()
    {
        this.RESET_TIPOLOGIA_FORNITURA();
    }
    @Mutation
    public RESET_TIPOLOGIA_FORNITURA()
    {
        this.TipologiaFornituraCreate.Code = "";
        this.TipologiaFornituraCreate.Name = "";
        this.TipologiaFornituraCreate.Errors = new Map<string, Array<string>>();
        this.TipologiaFornituraCreate.Users = [""];
    }

    // Edit SupplyTipology
    @Action({ rawError: true })
    public async EditSupplyTipology() : Promise<EditSupplyTipologyResponse>
    {
        try
        {
            let response: EditSupplyTipologyResponse = { Id: Guid.createEmpty(), };
            SharedModule.SET_ISLOADING(true);
            if(this.SupplyTipologyEdit.Command)
                response = await this._orderService.EditSupplyTipology(this.SupplyTipologyEdit.Command.Id, this.SupplyTipologyEdit.Command);

            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch( error )
        {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_SUPPLYTIPOLOGY_EDIT_ERRORS(errs);
            
            throw errs;
        }
    }
    @Action({ rawError: true})
    public async GetSupplyTipologyToEdit(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);

        this.SET_SUPPLYTIPOLOGY_EDIT_COMMAND(null);
        this.SET_SUPPLYTIPOLOGY_EDIT_VIEWMODEL(null);

        const supplyTipology: TipologiaFornituraModel = await this._orderService.GetTipologiaFornituraById(id.toString());

        this.SET_SUPPLYTIPOLOGY_EDIT_VIEWMODEL(supplyTipology);
        this.InitSupplyTipologyEdit();

        this.SET_SUPPLYTIPOLOGY_EDIT_ID(Guid.parse(supplyTipology.Id));
        this.SET_SUPPLYTIPOLOGY_EDIT_CODE(supplyTipology.Code);
        this.SET_SUPPLYTIPOLOGY_EDIT_NAME(supplyTipology.Name);

        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public InitSupplyTipologyEdit()
    {
        const command: EditSupplyTipologyCommand =
        {
            Id: Guid.createEmpty(),
            Code: '',
            Name: '',
        }
        this.SET_SUPPLYTIPOLOGY_EDIT_COMMAND(command);
    }
    @Mutation
    public SET_SUPPLYTIPOLOGY_EDIT_COMMAND(command: EditSupplyTipologyCommand | null)
    {
        this.SupplyTipologyEdit.Command = command;
    }
    @Mutation
    public SET_SUPPLYTIPOLOGY_EDIT_VIEWMODEL(viewModel: TipologiaFornituraModel | null)
    {
        this.SupplyTipologyEdit.View = viewModel;
    }
    @Mutation
    public SET_SUPPLYTIPOLOGY_EDIT_ID(id: Guid)
    {
        if(this.SupplyTipologyEdit.Command)
            this.SupplyTipologyEdit.Command.Id = id;
    }
    @Mutation
    public SET_SUPPLYTIPOLOGY_EDIT_CODE(code: string)
    {
        if(this.SupplyTipologyEdit.Command)
            this.SupplyTipologyEdit.Command.Code = code;
    }
    @Mutation
    public SET_SUPPLYTIPOLOGY_EDIT_NAME(name: string)
    {
        if(this.SupplyTipologyEdit.Command)
            this.SupplyTipologyEdit.Command.Name = name;
    }
    @Mutation
    public SET_SUPPLYTIPOLOGY_EDIT_ERRORS(errs: Map<string, Array<string>>)
    {
        this.SupplyTipologyEdit.Errors = errs;
    }

    // Approve SupplyTipology
    @Action({ rawError: true })
    public async ApproveSupplyTipology(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.TipologiaFornituraDetails == null)
                return Promise.reject("Details Supply Tipology not initialized, try reload the application");

            const ts = this.TipologiaFornituraDetails;
            const cmd = { Id: Guid.parse(ts.Id) };
            await this._orderService.ApproveSupplyTipology(cmd);

            this.SET_TIPOLOGIAFORNITURA_STATUS(EntityOrderStatus.Approved);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }

    // APPROVE DELETION
    @Action({ rawError: true })
    public async ApproveDeletionSupplyTipology(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.TipologiaFornituraDetails == null)
                return Promise.reject("Details Supply Tipology not initialized, try reload the application");

            const st = this.TipologiaFornituraDetails;
            const cmd: ApproveDeletionSupplyTipologyCommand = { Id: Guid.parse(st.Id) };
            await this._orderService.ApproveDeletionSupplyTipology(cmd);

            this.SET_TIPOLOGIAFORNITURA_STATUS(EntityOrderStatus.Deleted);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();

    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }

    return errs;
}

export const CreateTipologiaFornituraModule = getModule(CreateTipologiaFornituraStore);