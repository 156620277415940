















import { Component } from "vue-property-decorator";
import { CreateTipologiaFornituraModule } from './store';

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";

import PgaSupplyTipologyEdit from './Components/PgaSupplyTipologyEdit.vue';
import { EntityOrderStatus, TipologiaFornituraModel } from '@/services/iOrderService';
import { Guid } from 'guid-typescript';
import { SharedModule } from "@/feature/Shared/store";

@Component( {
	components: {
		PgaSupplyTipologyEdit
	}
} )
export default class SupplyTipologyPageEdit extends mixins( SharedMixin )
{
	get supplyTipologyEdit (): TipologiaFornituraModel
	{
		return CreateTipologiaFornituraModule.SupplyTipologyEdit.View as TipologiaFornituraModel;
	}

	private async Edit() : Promise<void>
	{
		await CreateTipologiaFornituraModule.EditSupplyTipology();
		this.$router.go(-1);
	}

	async mounted (): Promise<void>
	{
		const supplyTipologyId: string = this.$route.params.id;
		await CreateTipologiaFornituraModule.GetSupplyTipologyToEdit( Guid.parse( supplyTipologyId ) );
		if(this.supplyTipologyEdit.Status !== EntityOrderStatus.Created)
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE("SupplyTipology is already approved, can't be edit anymore");
			SharedModule.SET_ALERT_IS_VISIBLE(true);
			this.$router.go(-1);
		}
	}
}
