



















import { Component, Model } from "vue-property-decorator";
import { TipologiaFornituraModel } from "@/services/iOrderService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { CreateTipologiaFornituraModule } from '../store';

@Component( {} )
export default class PgaSupplyTipologyEdit extends mixins( SharedMixin )
{
	@Model( "", { type: Object } ) readonly Model!: TipologiaFornituraModel;

	get code (): string
	{
		return this.Model.Code;
	}
	set code ( value: string )
	{
		CreateTipologiaFornituraModule.SET_SUPPLYTIPOLOGY_EDIT_CODE( value );
	}

	get name (): string
	{
		return this.Model.Name;
	}
	set name ( value: string )
	{
		CreateTipologiaFornituraModule.SET_SUPPLYTIPOLOGY_EDIT_NAME( value );
	}

	get Errors(): Map<string, Array<string>>
	{
		return CreateTipologiaFornituraModule.SupplyTipologyEdit.Errors;
	}

	beforeDestroy() : void
	{
		CreateTipologiaFornituraModule.SET_SUPPLYTIPOLOGY_EDIT_ERRORS(new Map<string, Array<string>>());
	}
}
